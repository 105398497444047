import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import MainLayout from "components/Layout/MainLayout";
import Spinner from "components/Spinner/Spinner";
import { I_BillLog } from "etc/types";
import { getHotelBillLogDetail } from "api/hotel"; // API 호출 함수 (예: axios 사용)
import { EHotelProductType } from "etc/enum";

function HotelBillLogMembershipDetail() {
  const { hotelId, billLogId } = useParams<{ hotelId: string; billLogId: string }>();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isBillLog, setBillLog] = useState<I_BillLog | null>(null);

  const statusOptions = [
    // { id: 42, key: "hotelMembership", value: "구매예정" },
    { id: 43, key: "hotelMembership", value: "구매완료" },
    { id: 44, key: "hotelMembership", value: "사용완료" },
    // { id: 45, key: "hotelMembership", value: "취소신청" },
    { id: 46, key: "hotelMembership", value: "취소완료" },
    // { id: 47, key: "hotelMembership", value: "기간만료" },
  ];

  // 선택한 상태 관리
  const [selectedStatus, setSelectedStatus] = useState<{ [key: number]: string }>({});
  // 상태 변경 핸들러
  const handleStatusChange = (ticketId: number, newStatus: string) => {
    setSelectedStatus((prev) => ({ ...prev, [ticketId]: newStatus }));
  };

  useEffect(() => {
    getAsyncDetail();
  }, [hotelId, billLogId]);

  const getAsyncDetail = async () => {
    if (!hotelId || !billLogId) {
      console.error("Hotel ID or Product ID is missing");
      return;
    }

    setLoading(true);
    try {
      const data = await getHotelBillLogDetail(hotelId, billLogId);
      if (data.ok) {
        console.log('data : ', data);
        setBillLog(data.res);
      } else {
        alert("Failed to fetch product details");
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout>
      {isLoading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Container>
          <Title>상품 상세 정보</Title>
          {isBillLog ? (
            <DetailBox>
              <DetailItem>
                <DetailLabel>상품명:</DetailLabel> <DetailValue>{isBillLog.billLogHotelTicket[0]?.hotelProduct.title}</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>유형:</DetailLabel> 
                <DetailValue>
                  {isBillLog.billLogHotelTicket[0]?.hotelProductType === EHotelProductType.DAYCARE && "유치원"}
                  {isBillLog.billLogHotelTicket[0]?.hotelProductType === EHotelProductType.HOTELING && "호텔링"}
                </DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>단가:</DetailLabel> <DetailValue>{isBillLog.billLogHotelTicket[0]?.unitPrice.toLocaleString()}원</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>만료일:</DetailLabel> <DetailValue>{new Date(isBillLog.billLogHotelTicket[0]?.expriredAt).toLocaleDateString("ko-KR")}</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>상품 설명:</DetailLabel> <DetailValue>{isBillLog.billLogHotelTicket[0]?.hotelProduct.expireRule}</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>상태:</DetailLabel> <DetailValue>{isBillLog.billLogHotelTicket[0]?.orderStatus.value}</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>구매일:</DetailLabel> <DetailValue>{new Date(isBillLog.createdAt).toLocaleDateString("ko-KR")}</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>사용 갯수:</DetailLabel> <DetailValue>{isBillLog.billLogHotelTicket.filter(ticket => ticket.orderStatus.value === "사용완료").length}개</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>남은 갯수:</DetailLabel> <DetailValue>{isBillLog.billLogHotelTicket.filter(ticket => ticket.orderStatus.value !== "사용완료").length}개</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>전체 상품금액:</DetailLabel> <DetailValue>{isBillLog.totalProductPrice.toLocaleString()}원</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>구매금액:</DetailLabel> <DetailValue>{isBillLog.totalPaymentPrice.toLocaleString()}원</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>구매시 사용한 포인트:</DetailLabel> <DetailValue>{isBillLog.totalPointDiscount.toLocaleString()}포인트</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailLabel>구매한 회원정보:</DetailLabel> <DetailValue>{isBillLog.user.name} ({isBillLog.user.email}) / {isBillLog.user.phone}</DetailValue>
              </DetailItem>
              <TicketList>
                <strong>티켓 상태:</strong>
                <br />
                <TicketRow>
                  {isBillLog.billLogHotelTicket.map((ticket, index) => (
                    <TicketItem key={index}>
                      <FlexRow>
                        <FlexLabel>티켓 ID:</FlexLabel>
                        <FlexValue>{ticket.id}</FlexValue>
                      </FlexRow>
                      <FlexRow>
                        <FlexLabel>상태:</FlexLabel>
                        <FlexValue status={ticket.orderStatus.value}>{ticket.orderStatus.value}</FlexValue>
                      </FlexRow>
                      <FlexRow>
                        <FlexLabel>유형:</FlexLabel>
                        <FlexValue>
                          {ticket.hotelProductType === EHotelProductType.DAYCARE && "유치원"}
                          {ticket.hotelProductType === EHotelProductType.HOTELING && "호텔링"}
                        </FlexValue>
                      </FlexRow>


                      {/* 상태 변경 UI */}
                      <StatusChangeContainer>
                        <StatusSelect
                          value={selectedStatus[ticket.id] || ticket.orderStatus.value}
                          onChange={(e) => handleStatusChange(ticket.id, e.target.value)}
                        >
                          {statusOptions.map((option) => (
                            <option key={option.id} value={option.value}>
                              {option.value}
                            </option>
                          ))}
                        </StatusSelect>
                        <ChangeButton onClick={() => console.log(`상태 변경: 티켓 ${ticket.id}, ${selectedStatus[ticket.id] || ticket.orderStatus.value}`)}>
                          변경
                        </ChangeButton>
                      </StatusChangeContainer>

                    </TicketItem>
                  ))}
                </TicketRow>
              </TicketList>
            </DetailBox>
          ) : (
            <LoadingContainer>
              <p>상품 정보를 불러오는 중입니다...</p>
            </LoadingContainer>
          )}
          <BackButton onClick={() => navigate(-1)}>뒤로가기</BackButton>
        </Container>
      )}
    </MainLayout>
  );
}



export default HotelBillLogMembershipDetail;

// Styled Components
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const DetailBox = styled.div`
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const DetailItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
`;

const DetailLabel = styled.span`
  font-weight: bold;
  color: #555;
  width: 40%;
`;

const DetailValue = styled.span`
  color: #333;
  text-align: right;
  width: 60%;
`;

// 상태 변경 UI 스타일
const StatusChangeContainer = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 5px;
`;

const StatusSelect = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ChangeButton = styled.button`
  padding: 5px 10px;
  background-color: #DC3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const BackButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const TicketList = styled.div`
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
`;

const TicketRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const TicketItem = styled.div`
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  flex: 1;
  min-width: 100px;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
`;

const TableLabel = styled.span`
  font-weight: bold;
  width: 30%;
`;

const TableValue = styled.span`
  width: 70%;
  text-align: right;
  color: #333;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const FlexLabel = styled.span`
  font-weight: bold;
  color: #333;
`;

const FlexValue = styled.span<{ status?: string }>`
  color: ${({ status }) =>
    status === "구매완료" ? "#28a745" :
    status === "취소완료" ? "#dc3545" :
    status === "사용완료" ? "#5c23df" :
    "#333"};
  font-weight: bold;
`;
