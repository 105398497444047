import React, { useState, useEffect } from "react";
import styled from "styled-components";

interface CheckboxListProps {
  labels: { label: string; count: number }[];
  onChange: (selectedItems: { label: string; count: number }[]) => void;
}

const CheckboxList: React.FC<CheckboxListProps> = ({ labels, onChange }) => {
  const [checkedItems, setCheckedItems] = useState<{ label: string; count: number }[]>([]);

  // 개별 체크박스 핸들러
  const handleCheckboxChange = (item: { label: string; count: number }) => {
    const newCheckedItems = checkedItems.some((checked) => checked.label === item.label)
      ? checkedItems.filter((checked) => checked.label !== item.label) // 체크 해제
      : [...checkedItems, item]; // 체크 추가

    setCheckedItems(newCheckedItems);
    onChange(newCheckedItems);
  };

  // 전체 선택/해제 핸들러
  const handleSelectAll = () => {
    const newCheckedItems = checkedItems.length === labels.length ? [] : [...labels];
    setCheckedItems(newCheckedItems);
    onChange(newCheckedItems);
  };

  useEffect(() => {
    console.log("checkedItems: ", checkedItems);
  }, [checkedItems]);

  return (
    <CheckboxContainer>
      <h3>체크박스 리스트</h3>
      <br />
      <CheckboxLabel>
        <CheckboxInput
          type="checkbox"
          checked={checkedItems.length === labels.length}
          onChange={handleSelectAll}
        />
        전체 선택
      </CheckboxLabel>

      {labels.map((item) => (
        <CheckboxLabel key={item.label}>
          <CheckboxInput
            type="checkbox"
            checked={checkedItems.some((checked) => checked.label === item.label)}
            onChange={() => handleCheckboxChange(item)}
          />
          {item.label} ({item.count})
        </CheckboxLabel>
      ))}

      <SelectedItems>
        <strong>선택된 항목:</strong>{" "}
        {checkedItems.length ? checkedItems.map((item) => `${item.label} (${item.count})`).join(", ") : "없음"}
      </SelectedItems>
    </CheckboxContainer>
  );
};

export default CheckboxList;

// ✅ 스타일 컴포넌트 정의
const CheckboxContainer = styled.div`
  padding: 20px;
  max-width: 400px;
  margin: auto;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
  cursor: pointer;
`;

const SelectedItems = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: gray;
`;
