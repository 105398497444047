import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import MainLayout from "components/Layout/MainLayout";
import Spinner from "components/Spinner/Spinner";

function MonthlySalesReport() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [salesData, setSalesData] = useState<{ 
    offlineSales: number;
    onlineSales: {
      total: number;
      daycare: number;
      hotel: number;
    };
  } | null>({
    offlineSales: 5000000,
    onlineSales: {
      total: 8000000,
      daycare: 3000000,
      hotel: 5000000,
    },
  });

  useEffect(() => {
    // 실 데이터 요청 시 fetchSalesData() 활성화
    // fetchSalesData();
  }, []);

  const fetchSalesData = async () => {
    setLoading(true);
    try {
      // 실제 API 호출 시 아래 코드 활성화
      // const data = await getMonthlySalesReport();
      // if (data.ok) {
      //   setSalesData(data.res);
      // } else {
      //   alert("Failed to fetch sales data");
      // }
    } catch (error) {
      console.error("Error fetching sales data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout>
      {isLoading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Container>
          <Title>월별 매출 보고서</Title>
          {salesData && (
            <SalesGrid>
              <SalesSection>
                <SectionTitle>오프라인 매출</SectionTitle>
                <SalesAmount>{salesData.offlineSales.toLocaleString()}원</SalesAmount>
              </SalesSection>
              <SalesSection>
                <SectionTitle>온라인 매출</SectionTitle>
                <SalesDetail>
                  <span>총 매출:</span>
                  <strong>{salesData.onlineSales.total.toLocaleString()}원</strong>
                </SalesDetail>
                <SalesDetail>
                  <span>유치원 매출:</span>
                  <strong>{salesData.onlineSales.daycare.toLocaleString()}원</strong>
                </SalesDetail>
                <SalesDetail>
                  <span>호텔 매출:</span>
                  <strong>{salesData.onlineSales.hotel.toLocaleString()}원</strong>
                </SalesDetail>
              </SalesSection>
            </SalesGrid>
          )}
          <BackButton onClick={() => navigate(-1)}>뒤로가기</BackButton>
        </Container>
      )}
    </MainLayout>
  );
}

export default MonthlySalesReport;

// Styled Components
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const SalesGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const SalesSection = styled.div`
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
`;

const SalesAmount = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const SalesDetail = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-top: 5px;
  span {
    color: #555;
  }
  strong {
    font-weight: bold;
    color: #000;
  }
`;

const BackButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;