import {
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { getFcmMessageList, sendMultiPushV2, sendPushV2 } from 'api/fcm';
import AlertDialog from 'components/Dialog/Dialog';
import MainLayout from 'components/Layout/MainLayout';
import { I_FcmMessage } from 'etc/types';
import { useEffect, useState } from 'react';

function FcmSend() {
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [sendType, setSendType] = useState<'all' | 'one'>('all');
  const [messageType, setMessageType] = useState<'marketing' | 'serviceNotice'>('marketing')
  const [fcmMessageList, setFcmMessageList] = useState<I_FcmMessage[]>([]);
  const [selectedMessageId, setSelectedMessageId] = useState<number>(0);
  const [selectedRoute, setSelectedRoute] =
    useState<keyof typeof routeList>('null');
  const [routeParam, setRouteParam] = useState<string>('');
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    imageUrl: '',
  });
  const [userCode, setUserCode] = useState<string>('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const integerRegex = /^-?\d+$/;

  const routeList = {
    null: { label: '선택안함', route: 'null', params: {} },
    storeInquiry: {
      label: '스토어 문의내역',
      route: 'ContactHistory',
      params: { initalIndex: 1 },
    },
    serviceInquiry: {
      label: '서비스 문의내역',
      route: 'ContactHistory',
      params: { initalIndex: 0 },
    },
    festival: { label: '펫스티벌 메인', route: 'ContestHome', params: {} },
    travel: { label: '여행 메인', route: 'TravelMain', params: {} },
    hotel: { label: '호텔 메인', route: 'HotelHomePage', params: {} },
    store: { label: '스토어 메인', route: 'StoreHomePage', params: {} },

    storeDetail: {
      label: '상품 상세페이지',
      route: 'ProductDetail',
      params: { id: routeParam },
    },
  } as const;

  // 라우트 파람을 무조건 받아야하는 상세페이지들 리스트 (추후 fcm 전송전 유효성 검사에 사용)
  const mustHaveRouteParamList = ['storeDetail'];

  useEffect(() => {
    getAsyncFcmList();
  }, []);

  useEffect(() => {
    handleSelectMessage(selectedMessageId);
  }, [selectedMessageId]);

  /**
   * db에 저장된 fcm 메세지 리스트 가져오기
   */
  const getAsyncFcmList = async () => {
    const data = await getFcmMessageList(activePage, 30);

    // console.log('data : ', data.res);

    if (data.ok) {
      setFcmMessageList(data.res.list);
    } else {
      alert('잠시 후 다시 시도해주세요.');
      console.error(data.res.error.message);
    }
  };

  /**
   * 메세지 양식 선택시
   * @param id
   */
  const handleSelectMessage = (id: number) => {
    if (id === 0) {
      setFormData({ title: '', content: '', imageUrl: '' });
    } else {
      const selectedMessage = fcmMessageList.find(
        (message) => message.id === id
      );
      if (selectedMessage) {
        setFormData({
          title: selectedMessage.title || '',
          content: selectedMessage.content || '',
          imageUrl: selectedMessage.imageUrl || '',
        });
      }
    }
  };

  /**
   * fcm 발송
   * @returns
   */
  const handleSubmit = async () => {
    setConfirmModalOpen(false);

    if (!formData.imageUrl.startsWith('https://') && formData.imageUrl) {
      alert('이미지 URL은 https:// 프로토콜만 사용가능합니다.');
      return;
    }

    if (!formData.title || !formData.content) {
      alert('제목과 메세지는 필수입니다.');
      return;
    }

    if (mustHaveRouteParamList.includes(selectedRoute) && !routeParam) {
      alert('해당 페이지는 라우트 파라미터는 필수입니다.');
      return;
    }

    if (
      mustHaveRouteParamList.includes(selectedRoute) &&
      !integerRegex.test(routeParam)
    ) {
      alert('라우트 파라미터는 정수만 입력 가능합니다.');
      return;
    }

    const selectedRouteDetails = routeList[selectedRoute];
    const body = {
      title: formData.title,
      content: formData.content,
      imageUrl: formData.imageUrl,
      userCode,
      sendType,
      messageType,
      data: {
        route: selectedRouteDetails.route,
        routeParam: JSON.stringify(selectedRouteDetails.params),
      },
    };

    try {
      if (sendType === 'one') {
        if (!userCode) {
          alert('유저코드는 필수입니다.');
          return;
        }
        const res = await sendPushV2(body);
        console.log(res)
        if (!res.ok) {
          // console.log(res.res.error.message)
          // if (res.res.error.message.startWith('user not allowed')) {
          //   alert('푸시 전송 실패: 해당 유저는 푸시 수신을 거부했습니다.');
          // }
          console.log(res.res.error.message)
          if (res.res.error.message.startsWith('user not allowed')) {
            alert('전송실패 : 유저 수신거부')
          } else {

            alert('푸시 전송 실패');
          }
        } else {
          alert('푸시 전송 성공');
          resetForm();
        }

        return;
      } else {
        // 전체전송은 사람이 많을수록 작업이 오래걸려 일단 요청만하고 성공여부는 네트워크탭, db로 확인 해야함
        sendMultiPushV2(body);
        alert('푸시 전송 요청완료');
        resetForm();
      }

      // 대상이 많을수록 전송이 오래걸려 일단 요청만하고 성공여부는 네트워크탭, db로 확인 해야함
    } catch (error) {
      console.error('푸시 전송 실패:', error);
      alert('푸시 전송 중 오류가 발생했습니다.');
    }
  };

  /**
   * 폼 초기화
   */
  const resetForm = () => {
    setFormData({
      title: '',
      content: '',
      imageUrl: '',
    });
    setUserCode('');
  };
  /**
   * 라우트파람에 정수타입 만 들어오는지 검증
   * @param input
   * @returns
   */
  function validateAndConvertToInteger(input: string): number | void {
    const convertedNumber = parseFloat(input);

    // 숫자로 변환 불가능 또는 정수가 아닌 경우
    if (isNaN(convertedNumber) || !Number.isInteger(convertedNumber)) {
      alert(`"${input}"는 유효한 정수가 아닙니다.`);
      return;
    }

    return convertedNumber;
  }

  return (
    <MainLayout>
      <Box display='flex' flexDirection='row'>
        <Box>
          <Box display={'flex'} flexDirection='row' gap={5}>
            <Box>
              <InputLabel id='send-type-label'>발송대상</InputLabel>
              <Select
                sx={{ width: '100px' }}
                labelId='send-type-label'
                id='send-type-select'
                value={sendType}
                onChange={(e) => setSendType(e.target.value as 'all' | 'one')}
              >
                <MenuItem value='all'>전체</MenuItem>
                <MenuItem value='one'>단일</MenuItem>
              </Select>
            </Box>

            <Box>
              <InputLabel id='send-type-label'>메세지타입</InputLabel>
              <Select
                sx={{ width: '200px' }}
                labelId='send-type-label'
                id='send-type-select'
                value={messageType}
                onChange={(e) => setMessageType(e.target.value as 'marketing' | 'serviceNotice')}
              >
                <MenuItem value='marketing'>마케팅광고</MenuItem>
                <MenuItem value='serviceNotice'>서비스공지</MenuItem>
              </Select>
            </Box>
          </Box>

          {sendType === 'one' && (
            <>
              <InputLabel sx={{ mt: 2 }}>
                유저코드 (단일 선택시 *필수)
              </InputLabel>
              <TextField
                sx={{ width: '400px' }}
                value={userCode}
                onChange={(e) => setUserCode(e.target.value)}
              />
            </>
          )}

          <InputLabel sx={{ mt: 2 }}>타이틀 *필수</InputLabel>
          <TextField
            sx={{ width: '400px' }}
            value={formData.title}
            onChange={(e) =>
              setFormData({ ...formData, title: e.target.value })
            }
          />

          <InputLabel sx={{ mt: 2 }}>메세지 *필수</InputLabel>
          <TextField
            sx={{ width: '400px' }}
            multiline
            rows={4}
            value={formData.content}
            onChange={(e) =>
              setFormData({ ...formData, content: e.target.value })
            }
          />

          <InputLabel sx={{ mt: 2 }}>라우트</InputLabel>
          <Select
            sx={{ width: '400px' }}
            value={selectedRoute}
            onChange={(e) =>
              setSelectedRoute(e.target.value as keyof typeof routeList)
            }
          >
            {Object.entries(routeList).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value.label}
              </MenuItem>
            ))}
          </Select>

          {mustHaveRouteParamList.includes(selectedRoute) && (
            <>
              <InputLabel sx={{ mt: 2 }}>라우트 파라미터</InputLabel>
              <TextField
                sx={{ width: '400px' }}
                placeholder='예: 상세페이지 3번글 = 3'
                value={routeParam}
                onChange={(e) => setRouteParam(e.target.value)}
              />
            </>
          )}

          <InputLabel sx={{ mt: 2 }}>이미지 URL</InputLabel>
          <TextField
            sx={{ width: '400px' }}
            value={formData.imageUrl}
            onChange={(e) =>
              setFormData({ ...formData, imageUrl: e.target.value })
            }
          />

          <img
            src={formData.imageUrl}
            alt='미리보기 이미지'
            style={{ width: '200px', height: '200px', marginTop: '16px' }}
          />
        </Box>
        <Box sx={{ ml: 10 }}>
          <InputLabel>메세지 리스트</InputLabel>
          <Select
            sx={{ width: '400px' }}
            value={selectedMessageId}
            onChange={(e) => setSelectedMessageId(e.target.value as number)}
          >
            <MenuItem value={0}>선택안함</MenuItem>
            {fcmMessageList.map((message) => (
              <MenuItem key={message.id} value={message.id}>
                {message.title}
              </MenuItem>
            ))}
          </Select>

          <Button
            onClick={() => setConfirmModalOpen(true)}
            variant='outlined'
            sx={{ mt: 2 }}
          >
            전송
          </Button>
        </Box>
        <Box sx={{ ml: 10 }}>
          <Typography>
            이미지 url은 https:// 프로토콜만 사용가능 (http:// 불가)
          </Typography>
          <Typography>
            png,jpg 타입만 가능하며 용량 300kb 이하로 업로드 해주세요.
          </Typography>
          <Typography>
            실제 유저에게 발송전 내부직원에게 발송 테스팅 후 발송해주세요
            (이미지가 잘 보내지는지)
          </Typography>
          <Typography>법인폰 햇님이 #4889634</Typography>
          <Typography>이두리대표 호호 #6854435</Typography>
          <Typography>유재현개발자 #6458759</Typography>
          <Typography>
            본인계정 추가하고싶으신 분들은 개발팀에 닉네임 전달주시면
            추가해두겠습니다.
          </Typography>

          <Typography>
            유재현네이버개발 : #5237355
          </Typography>
        </Box>
      </Box>

      <AlertDialog
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        title='푸시 메세지를 전송 하시겠습니까?'
        description='전송을 누르면 취소할 수 없습니다. 내용과 대상이 정확하다면 확인 버튼을 눌러주세요.'
        onConfirm={handleSubmit}
      />
    </MainLayout>
  );
}

export default FcmSend;
