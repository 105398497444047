import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainLayout from "components/Layout/MainLayout";
import { getMarketingUtmTotalLog, getMarketingUtmIdLog } from "api/marketingUtm";
import useLogout from "hooks/useLogout";
import UtmLineChart from "components/Chart/UtmChart";
import UtmChartMonth from "components/Chart/UtmChartMonth";
import Dropdown from "components/Dropdown/Dropdown";
import CheckboxList from "components/Chart/CheckboxList";

// ✅ UTM 데이터 타입 정의
interface UtmItem {
  label: string;
  count: number;
}

function UtmChart() {
  const Logout = useLogout();
  
  // ✅ UTM 데이터 상태 관리
  const [utmData, setUtmData] = useState<UtmItem[]>([]);
  const [checkedItems, setCheckedItems] = useState<UtmItem[]>([]);
  const [selectedActivityTypeItem, setSelectedActivityTypeItem] = useState<string>("");
  const [isActivityTypeDropdownOpen, setIsActivityTypeDropdownOpen] = useState<boolean>(false);

  // ✅ 차트 데이터 상태
  const [totalChartData, setTotalChartData] = useState({
    labels: [] as string[],
    datasets: [
      {
        label: "전체 방문자",
        data: [] as number[],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  });

  const [monthlyChartData, setMonthlyChartData] = useState({
    labels: [] as string[],
    datasets: [
      {
        label: "월별 방문자",
        data: [] as number[],
        backgroundColor: "rgba(0, 72, 255, 0.441)",
        borderColor: "#224bed",
        borderWidth: 1,
      },
    ],
  });

  // ✅ 데이터 가져오기 (API 호출)
  useEffect(() => {
    getAsyncUtmList();
  }, []);

  // ✅ 체크된 항목이 변경될 때 차트 업데이트
  useEffect(() => {
    if (checkedItems.length > 0) {
      updateChartData(checkedItems);
    }
  }, [checkedItems]);

  // ✅ API 호출 및 데이터 변환
  const getAsyncUtmList = async () => {
    const data = await getMarketingUtmTotalLog();
    if (data.ok) {
      const formattedData: UtmItem[] = data.res.labels.map((label: string, index: number) => ({
        label,
        count: Number(data.res.data[index]),
      }));

      setUtmData(formattedData);
      setCheckedItems(formattedData); // 기본적으로 모든 데이터를 선택
      updateChartData(formattedData);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert("잠시 후 다시 시도해주세요.");
        console.error(data.res.error.message);
      }
    }
  };

  // ✅ 특정 UTM 데이터 가져오기 (월별 차트용)
  const getAsyncUtmNameList = async (utmName: string) => {
    const data = await getMarketingUtmIdLog(utmName);
    if (data.ok) {
      setMonthlyChartData({
        labels: data.res.labels,
        datasets: [
          {
            label: "월별 방문자",
            data: data.res.data,
            backgroundColor: "rgba(0, 72, 255, 0.441)",
            borderColor: "#224bed",
            borderWidth: 1,
          },
        ],
      });
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert("잠시 후 다시 시도해주세요.");
        console.error(data.res.error.message);
      }
    }
  };

  // ✅ 차트 데이터 업데이트
  const updateChartData = (selectedItems: UtmItem[]) => {
    const newLabels = selectedItems.map((item) => item.label);
    const newData = selectedItems.map((item) => item.count);

    setTotalChartData({
      labels: newLabels,
      datasets: [
        {
          label: "전체 방문자",
          data: newData,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
      ],
    });
  };

  return (
    <MainLayout>
      <ChartContainer>
        {/* ✅ 체크박스 리스트 */}
        <CheckboxWrapper>
          <CheckboxList labels={utmData} onChange={setCheckedItems} />
        </CheckboxWrapper>

        {/* ✅ 차트 영역 */}
        <ChartSection>
          {/* ✅ 전체 방문자 차트 */}
          <ChartWrapper>
            <UtmLineChart datas={totalChartData} />
          </ChartWrapper>

          {/* ✅ 월별 방문자 차트 */}
          <ChartWrapper>
            <DropdownRow>
              <Dropdown
                list={utmData.map((item) => item.label)}
                selectedItem={selectedActivityTypeItem}
                isOpen={isActivityTypeDropdownOpen}
                setIsOpen={setIsActivityTypeDropdownOpen}
                onClick={(item: string) => {
                  setSelectedActivityTypeItem(item);
                  getAsyncUtmNameList(item);
                }}
              />
            </DropdownRow>
            <UtmChartMonth datas={monthlyChartData} />
          </ChartWrapper>
        </ChartSection>
      </ChartContainer>
    </MainLayout>
  );
}

export default UtmChart;

// ✅ 스타일 컴포넌트 정의
const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  padding-top: 20px;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  width: 25%;
  max-width: 300px;
`;

const ChartSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
`;

const ChartWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
`;

const DropdownRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;
