import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import MainLayout from "components/Layout/MainLayout";
import Spinner from "components/Spinner/Spinner";
import { I_HotelProduct } from "etc/types";
import { getHotelProductList } from "api/hotel";
import { EHotelProductSaleType, EHotelProductType } from "etc/enum";
import StatusButton from "components/Button/StatusButton";
import DetailButton from "components/Button/DetailButton";

function HotelProductList() {
  const navigate = useNavigate();
  const { hotelId } = useParams<{ hotelId: string }>();

  const [productList, setProductList] = useState<I_HotelProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // ✅ 선택된 상태 (전체 상품을 위해 '' 값을 추가)
  const [selectedSaleType, setSelectedSaleType] = useState<"ALL" | EHotelProductSaleType>("ALL");
  const [selectedMembershipType, setSelectedMembershipType] = useState<'' | 'MEMBERSHIP' | 'ONEDAY'>('');
  const [selectedCategory, setSelectedCategory] = useState<'' | EHotelProductType>('');

  useEffect(() => {
    fetchProductList();
  }, []);

  const fetchProductList = async () => {
    if (!hotelId) return;

    setLoading(true);
    const data = await getHotelProductList(hotelId);

    if (data.ok) {
      setProductList(data.res.list);
    } else {
      alert("데이터를 불러오는 데 실패했습니다.");
    }
    setLoading(false);
  };

  // ✅ 전체 상품을 선택하면 하위 필터 초기화
  const handleSaleTypeChange = (value: "ALL" | EHotelProductSaleType) => {
    setSelectedSaleType(value);
    if (value === "ALL") {
      setSelectedMembershipType('');
      setSelectedCategory('');
    }
  };

  // ✅ 전체 상품이 선택되지 않은 경우만 상품 유형 변경 가능
  const handleMembershipTypeChange = (value: '' | 'MEMBERSHIP' | 'ONEDAY') => {
    setSelectedMembershipType(value);
    setSelectedCategory(''); // 상품 유형이 바뀌면 세부 유형 초기화
  };

  const filteredProducts = productList.filter(product =>
    (selectedSaleType === "ALL" || product.hotelProductServiceType === selectedSaleType) &&
    (selectedMembershipType === '' || (selectedMembershipType === 'MEMBERSHIP'
      ? [EHotelProductType.HOTELING, EHotelProductType.DAYCARE].includes(product.hotelProductType)
      : [EHotelProductType.ONEDAY, EHotelProductType.ONEDAY_HOTELING, EHotelProductType.TIMECARE].includes(product.hotelProductType))) &&
    (selectedCategory === '' || product.hotelProductType === selectedCategory)
  );

  return (
    <MainLayout>
      {loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Container>
          {/* ✅ 드롭다운 영역 */}
          <DropdownContainer>
            <DropdownWrapper>
              <label>판매 유형:</label>
              <Select value={selectedSaleType} onChange={(e) => handleSaleTypeChange(e.target.value as "ALL" | EHotelProductSaleType)}>
                <option value="ALL">전체 상품</option>
                <option value={EHotelProductSaleType.SALE}>판매상품</option>
                <option value={EHotelProductSaleType.SERVICE}>서비스상품</option>
              </Select>
            </DropdownWrapper>

            {/* ✅ "전체 상품"을 선택한 경우 상품 유형 & 세부 유형 드롭다운 비활성화 */}
            <DropdownWrapper shiftLeft>
              <label>상품 유형:</label>
              <Select
                value={selectedMembershipType}
                onChange={(e) => handleMembershipTypeChange(e.target.value as '' | 'MEMBERSHIP' | 'ONEDAY')}
                disabled={selectedSaleType === "ALL"}
              >
                <option value="">전체</option>
                <option value="MEMBERSHIP">멤버쉽권</option>
                <option value="ONEDAY">1일권</option>
              </Select>
            </DropdownWrapper>

            <DropdownWrapper shiftLeft>
              <label>상품 세부 유형:</label>
              <Select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value as '' | EHotelProductType)}
                disabled={selectedSaleType === "ALL" || selectedMembershipType === ''}
              >
                <option value="">전체</option>
                {selectedMembershipType === "MEMBERSHIP" ? (
                  <>
                    <option value={EHotelProductType.HOTELING}>호텔링</option>
                    <option value={EHotelProductType.DAYCARE}>유치원</option>
                  </>
                ) : (
                  <>
                    <option value={EHotelProductType.ONEDAY_HOTELING}>호텔링</option>
                    <option value={EHotelProductType.ONEDAY}>유치원</option>
                    <option value={EHotelProductType.TIMECARE}>시간권</option>
                  </>
                )}
              </Select>
            </DropdownWrapper>
          </DropdownContainer>

          {/* ✅ 상품 리스트 테이블 */}
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>상품명</Th>
                <Th>가격</Th>
                <Th>원가</Th>
                <Th>수량</Th>
                <Th>할인율</Th>
                <Th>유효기간</Th>
                <Th>타입</Th>
                <Th>판매 타입</Th>
                <Th>상태</Th>
                <Th>관리</Th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product) => (
                <Tr key={product.id}>
                  <Td>{product.id}</Td>
                  <Td>{product.title}</Td>
                  <Td>{product.price.toLocaleString()}원</Td>
                  <Td>{product.originPrice?.toLocaleString()}원</Td>
                  <Td>{product.quantity}</Td>
                  <Td>{product.salePercent}%</Td>
                  <Td>{product.validityPeriod}일</Td>
                  <Td>{product.hotelProductType}</Td>
                  <Td>{product.hotelProductServiceType === "SALE" ? "판매상품" : "서비스상품"}</Td>
                  <Td>
                    <StatusButton
                      isActive={product.hotelProductStatus === "ACTIVE"}
                      onClick={() => console.log(`Toggling status for ID: ${product.id}`)}
                    >
                      {product.hotelProductStatus}
                    </StatusButton>
                  </Td>
                  <Td>
                    <DetailButton onClick={() => navigate(`/hotel/${hotelId}/product/${product.id}`)}>수정</DetailButton>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </Container>
      )}
    </MainLayout>
  );
}

export default HotelProductList;

// ✅ Styled Components 추가
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Container = styled.div`
  padding: 20px;
`;

const DropdownContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
`;

const DropdownWrapper = styled.div<{ shiftLeft?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  ${({ shiftLeft }) => shiftLeft && `margin-left: 15px;`}
  /* margin-left: 10px; */
`;

const Select = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f2f2f2;
  text-align: left;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
`;
